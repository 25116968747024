import React from 'react'
import { Card, Text, Button } from 'theme-ui'
import Section from '@components/Section'
import { Link } from 'gatsby'

const Commitment = props => (
  <Section aside {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      Se identificou com nosso Propósito de impactar positivamente empresas e o ecossistema de empreendedorismo e quer se juntar à nossa Causa? Estamos aumentando o nosso time. Conte um pouco sobre você e o que te motiva.
      </Text>

      <Button variant='primary' as={Link} to='https://xtree.com.vc/pb/vagas'>
        Ver vagas
      </Button>
    </Card>
  </Section>
)

export default Commitment
