import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'

/**
 * How to enable form integration:
 *
 * 1) Shadow this component
 * 2) Remove the demo attribute from the form tag.
 * 3) Add your action end-point to the form tag.
 * 4) If required by your form API provider, add the hidden input(s).
 *
 * Some recommended serverless form providers:
 * Getform (https://www.gatsbyjs.com/docs/building-a-contact-form/#getform)
 * Formspree (https://www.gatsbyjs.com/docs/building-a-contact-form/#formspree)
 * Netlify Form (https://www.gatsbyjs.com/docs/building-a-contact-form/#netlify)
 *
 */

const ContactForm = ({ handleSubmit, submitting, success }) => (
  <Box variant='forms.row'>
    <p>
      Quer deixar um recado, feedback ou sugestão? Tirar uma dúvida ou trocar uma ideia?<b> Adoraríamos ouvir você!</b>
    </p>

    <Button variant='primary' as={Link} to='https://conecte.xtree.com.vc/duvidas-e-sugestoes'>
      Entre em contato
  </Button>
  </Box>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
