import React from 'react'
import { Card, Text, IconButton, Button } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import { Link } from 'gatsby'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  return (
    <Section aside>
      <Card variant='paper'>
        <Text variant='p'>Se identificou com o nosso Propósito e quer dar visibilidade à sua marca como Apoiador ou Patrocinador?</Text>
        <Text variant='p'>
          Vamos conversar!
        </Text>

        <Button variant='primary' as={Link} to='https://conecte.xtree.com.vc/colabore-com-conteudos-praticos '>
          Entre em contato
        </Button>

      </Card>
    </Section>
  )
}

export default ContactInfo
